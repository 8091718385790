export function throwIfNull<T>(value: T | null, message = "Value is null"): T {
  if (value === null) {
    throw new Error(message);
  }
  return value;
}

export function throwIfUndefined<T>(value: T | undefined, message = "Value is undefined"): T {
  if (value === undefined) {
    throw new Error(message);
  }
  return value;
}
